<template>
  <div :style="cssVars">
    <slot />
  </div>
</template>

<script lang="ts">
const cssSizeUnits = ['px', 'em', 'rem', 'vw', 'vh', '%', 'pt'];
const fontSizeValidator = (value: string) => cssSizeUnits.some((unit) => value.endsWith(unit));
</script>

<script setup lang="ts">
import { provide, ref, watch } from 'vue';
import HelperService from '@utils/helper-service';

const props = defineProps({
  buttonColor: {
    type: String,
    default: '#fff',
  },
  buttonBackgroundColor: {
    type: String,
    default: '#000000',
  },
  buttonHoverBackgroundColor: {
    type: String,
    default: '#333333',
  },
  tileRegisteredRibbonBackgroundColor: {
    type: String,
    default: '#9cd1ea',
  },
  tileRegisteredRibbonBorderColor: {
    type: String,
    default: '#1195D1',
  },
  tileRegisteredRibbonColor: {
    type: String,
    default: '#093e57',
  },
  tileCompletedRibbonBackgroundColor: {
    type: String,
    default: '#005CB8',
  },
  tileCompletedRibbonBorderColor: {
    type: String,
    default: '#1195D1',
  },
  tileCompletedRibbonColor: {
    type: String,
    default: '#FFFFFF',
  },
  tileActiveRibbonBackgroundColor: {
    type: String,
    default: '#002952',
  },
  tileActiveRibbonBorderColor: {
    type: String,
    default: '#584621',
  },
  tileActiveRibbonColor: {
    type: String,
    default: '#FFFFFF',
  },
  tileGetStartedRibbonBackgroundColor: {
    type: String,
    default: '#CCE0F5',
  },
  tileGetStartedRibbonBorderColor: {
    type: String,
    default: '#372158',
  },
  tileGetStartedColor: {
    type: String,
    default: '#093e57',
  },
  tileStyle: {
    type: String,
    default: 'default',
  },
  tileBorderColor: {
    type: String,
    default: '#d5d5d5',
  },
  tileBorderWidth: {
    type: [String, Number],
    default: 1,
  },
  tileBorderWidthUnit: {
    type: String,
    default: 'px',
    validator: (value: string) => cssSizeUnits.includes(value),
  },
  tileHoverBorderColor: {
    type: String,
    default: '#1195D1',
  },
  tileFocusBorderColor: {
    type: String,
    default: '#116F9C',
  },
  tileFocusBoxColor: {
    type: String,
    default: '#50aedc',
  },
  tileCalloutColor: {
    type: String,
    default: '#5a697c',
  },
  tileBackgroundColor: {
    type: String,
    default: '#fff',
  },
  fontFamily: {
    type: String,
    default: "'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif",
  },
  h2Color: {
    type: String,
    default: '#282f38',
  },
  h1FontSize: {
    type: String,
    default: '24px',
    validator: fontSizeValidator,
  },
  h1FontWeight: {
    type: [String, Number],
    default: 400,
  },
  h2FontSize: {
    type: String,
    default: '20px',
    validator: fontSizeValidator,
  },
  h2FontWeight: {
    type: [String, Number],
    default: 600,
  },
  h2TileTitleFontSize: {
    type: String,
    default: '20px',
    validator: fontSizeValidator,
  },
  h2TileTitleFontWeight: {
    type: [String, Number],
    default: 600,
  },
  h3FontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  h3FontWeight: {
    type: [String, Number],
    default: 300,
  },
  h4FontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  h4FontWeight: {
    type: [String, Number],
    default: 300,
  },
  h5FontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  h5FontWeight: {
    type: [String, Number],
    default: 300,
  },
  h6FontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  h6FontWeight: {
    type: [String, Number],
    default: 300,
  },
  paragraphColor: {
    type: String,
    default: '#282f38',
  },
  paragraphFontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  paragraphFontWeight: {
    type: [String, Number],
    default: 300,
  },
  buttonFontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  buttonFontWeight: {
    type: [String, Number],
    default: 600,
  },
  linkFontSize: {
    type: String,
    default: '16px',
    validator: fontSizeValidator,
  },
  linkFontWeight: {
    type: [String, Number],
    default: 600,
  },
});

type WindowWithCustomDesignTokens = Window & {
  THEME_PROVIDER_TOKEN_OVERRIDES: typeof props;
  SJ_THEME_PROVIDER_TOKENS: typeof props;
};

const windowWithCustomDesignTokens = window as unknown as WindowWithCustomDesignTokens;

const customDesignTokens = ref(windowWithCustomDesignTokens.THEME_PROVIDER_TOKEN_OVERRIDES || {});
const theme = ref({ ...props, ...customDesignTokens });
const cssVars = ref({});

const updateProvider = () => {
  if (customDesignTokens) {
    theme.value = { ...props, ...customDesignTokens.value };
    provide('theme', theme.value);
  } else {
    theme.value = props;
    provide('theme', props);
  }

  cssVars.value = Object.keys(theme.value).reduce((acc: any, key) => {
    const variablename = HelperService.camelCaseToKebabCase(key, true);

    acc[`--${variablename}`] = theme.value[key as keyof typeof theme.value];
    return acc;
  }, {});

  windowWithCustomDesignTokens.SJ_THEME_PROVIDER_TOKENS = theme.value;
};

watch(
  () => props,
  () => updateProvider(),
  { immediate: true }
);
</script>
