export default class HelperService {
  static loadScript(attributes, $rootElement, callback) {
    const $scriptElement = this.createElement('script', attributes);

    if (!$rootElement) {
      document.body.appendChild($scriptElement);
    } else if (!$rootElement.contains($scriptElement)) {
      $rootElement.appendChild($scriptElement);
    }

    $scriptElement.onerror = (e) => {
      throw new Error(e);
    };

    $scriptElement.onload = callback;
  }

  static createElement(tagName, attributes, children) {
    const elem = document.createElement(tagName);

    if (attributes) {
      const bracketNotationAttrs = ['textContent', 'innerHTML', 'innerText'];

      Object.keys(attributes).forEach((key) => {
        if (bracketNotationAttrs.includes(key)) {
          elem[key] = attributes[key];

          return;
        }

        elem.setAttribute(key, attributes[key]);
      });
    }

    if (children) {
      children
        .filter((child) => !!child)
        .forEach((child) => {
          elem.appendChild(child);
        });
    }

    return elem;
  }

  static replaceClass(node, oldClass, newClass) {
    node.classList.remove(oldClass);
    node.classList.add(newClass);
  }

  static safeDefineCustomElement(name, cls) {
    if (!customElements.get(name)) {
      customElements.define(name, cls);
    }
  }

  static camelCaseToKebabCase(str, combineNumbers = false) {
    return str
      .split('')
      .map((letter) => {
        if (letter !== letter.toUpperCase() || (combineNumbers && !Number.isNaN(parseInt(letter, 10)))) {
          return letter;
        }

        return `-${letter.toLowerCase()}`;
      })
      .join('');
  }
}
